// @flow strict

import { type Node } from 'react';
import clsx from 'clsx';

import { useConfigContext } from '../config-wrapper';

import { FeedBackOptions, useFeedbackContext } from './feedback-context';
import { FeedbackIconPositiv } from './icons/feedback-positive';
import { FeedbackIconNegative } from './icons/feedback-negative';

import './feedback.less';

// type of component props
type FeedbackProps = {
  question: number,
};

/**
 * Feedback component.
 * Lets user give feedback to given question.
 *
 * @param {FeedbackProps} props - component props
 *
 * @returns {Node}
 */
export function Feedback({ question }: FeedbackProps): Node {
  // get contexts
  const config = useConfigContext();
  const { choices, trackFeedback } = useFeedbackContext();

  if (!config.isFeedbackActive) {
    return null;
  }

  // get choice for current question
  const choiceForQuestion = choices.find(
    (choice) => choice.question === question,
  );

  // get feedback option for choice
  const selected =
    choiceForQuestion != null ? choiceForQuestion.feedback : null;

  // render component
  return (
    <div className={config.generateClassName('feedback')}>
      <label className={config.generateClassName('feedback__label')}>
        {config.loc('feedback')}
      </label>
      <div className={config.generateClassName('feedback__actions')}>
        <div
          onClick={() =>
            trackFeedback(question, FeedBackOptions.FEEDBACK_POSITIVE)
          }
          className={clsx(
            config.generateClassName('feedback__action'),
            config.generateClassName('feedback__action--positive'),
            {
              [config.generateClassName('feedback__action--selected')]:
                selected === FeedBackOptions.FEEDBACK_POSITIVE,
            },
          )}>
          {FeedbackIconPositiv}
        </div>
        <div
          onClick={() =>
            trackFeedback(question, FeedBackOptions.FEEDBACK_NEGATIVE)
          }
          className={clsx(
            config.generateClassName('feedback__action'),
            config.generateClassName('feedback__action--negative'),
            {
              [config.generateClassName('feedback__action--selected')]:
                selected === FeedBackOptions.FEEDBACK_NEGATIVE,
            },
          )}>
          {FeedbackIconNegative}
        </div>
      </div>
    </div>
  );
}
