// @flow strict

import { type Node } from 'react';

import { useConfigContext } from '@omq/shared';

import { URLStore } from '../../stores/url-store';

import './navigation.less';

export type NavigationItemType = {
  id: number,
  name: string,
  href: string | null,
};

type NavigationItemClickHandler = (item: NavigationItemType) => void;

/**
 * Type for component properties.
 */
type NavigationProps = {
  items: Array<NavigationItemType>,
  onItemClick: NavigationItemClickHandler,
  styleName?: string,
};

type NavigationItemProps = {
  item: NavigationItemType,
  onClick: NavigationItemClickHandler,
};

/**
 * Render a single navigation item.
 *
 * @param {NavigationItem} item - item to render
 * @param {NavigationItemClickHandler} clickHandler - item click handler
 *
 * @returns {Node}
 */
function NavigationItem({ item, onClick }: NavigationItemProps): Node {
  const config = useConfigContext();

  // wrap click handler to prevent event propagation
  // do not follow the link
  const handleClick = (event) => {
    event.preventDefault();
    onClick(item);
  };

  // if href prop is set, render link
  // otherwise just plain text
  return (
    <li
      key={item.id}
      className={config.generateClassName('help-navigation__item')}>
      {item.href == null ? (
        item.name
      ) : (
        <a
          href={URLStore.buildAbsolutePath(item.href)}
          title={item.name}
          onClick={handleClick}
          className={config.generateClassName('help-navigation__link')}>
          {item.name}
        </a>
      )}
    </li>
  );
}

/**
 * Render navigation with list of items.
 *
 * @param {NavigationProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function Navigation({
  items,
  onItemClick,
  styleName,
}: NavigationProps): Node {
  const config = useConfigContext();

  return (
    <nav
      className={`${config.generateClassName('help-navigation')} ${
        styleName || ''
      }`}>
      <ul className={config.generateClassName('help-navigation__list')}>
        {items.length === 0 ? (
          <li>&nbsp;</li>
        ) : (
          items.map((item) => (
            <NavigationItem key={item.id} item={item} onClick={onItemClick} />
          ))
        )}
      </ul>
    </nav>
  );
}
