// @flow strict

import { useState } from 'react';

export type Cookie = {
  session: number,
  expires: number,
  content?: {
    feedback?: Array<*>,
  },
};

// time until cookie expires in ms
const MAX_COOKIE_DURATION = 60 * 60 * 1000;

export function dateOfNextHour(): Date {
  const date = new Date();

  const ms = date.getTime() + MAX_COOKIE_DURATION;
  date.setTime(ms);

  return date;
}

/**
 * Compare passed cookie content objects.
 *
 * @param cookieContent - obj a
 * @param content - obj b
 *
 * @returns {boolean}
 */
function cookieContentIsEqual(cookieContent, content) {
  return JSON.stringify(cookieContent || {}) === JSON.stringify(content || {});
}

/**
 * Read cookie for passed name.
 *
 * @param {string} cookieName - name of cookie
 *
 * @returns {Cookie|null}
 */
function readCookie(cookieName: string): Cookie | null {
  const match: ?Array<string> = document.cookie.match(
    new RegExp(`${cookieName}=([^;]+)`),
  );

  if (match == null) {
    return null;
  }

  return JSON.parse(decodeURIComponent(match[1]));
}

/**
 * Write session to cookie.
 *
 * @param {string} cookieName - name of cookie
 * @param {number} session - id of session
 * @param {{}} content - optional content
 *
 * @returns {Cookie}
 */
function writeCookie(
  cookieName: string,
  session: number,
  content?: $PropertyType<Cookie, 'content'>,
): Cookie {
  // get timestamp of next hour
  const expirationDate = dateOfNextHour();

  // collect cookie data
  const cookie = {
    session,
    ...(content != null ? { content } : null),
    expires: expirationDate.getTime(),
  };

  // build cookie content
  const cookieContent = `${encodeURIComponent(
    JSON.stringify(cookie),
  )}; path=/; expires=${expirationDate.toUTCString()};`;

  // write cookie content
  document.cookie = `${cookieName}=${cookieContent}`;
  return cookie;
}

/**
 * Delete cookie by setting expiration to 0.
 *
 * @param {string} cookieName - name of cookie
 */
function clearCookie(cookieName: string) {
  const date = new Date();
  date.setTime(0);

  // write cookie
  document.cookie = `${cookieName}=; path=/; expires=${date.toUTCString()}`;
}

type CookieHookType = [
  Cookie | null,
  (session: number | null, content?: $PropertyType<Cookie, 'content'>) => void,
];

/**
 * Custom hook to handle cookie I/O .
 *
 * @param {string} cookieName - name of cookie
 *
 * @returns {[Cookie, (session: number | null) => void]}
 */
export function useCookie(cookieName: string): CookieHookType {
  const browserCookie = readCookie(cookieName);

  // add state for cookie
  const [cookie, setCookie] = useState<Cookie | null>(browserCookie);

  // create setter
  const storeCookie = (
    session: number | null,
    content?: $PropertyType<Cookie, 'content'>,
  ): void => {
    // if session is null, clear cookie
    if (session == null) {
      clearCookie(cookieName);

      // update state
      setCookie(null);
      return;
    }

    //  if nothing changed, do nothing
    if (
      cookie &&
      cookie.session === session &&
      cookieContentIsEqual(cookie.content, content)
    ) {
      return;
    }

    // update cookie
    setCookie(writeCookie(cookieName, session, content));
  };

  // return cookie & setter
  return [cookie, storeCookie];
}

/**
 * Helper function to check if cookie is expired.
 *
 * @param {Cookie} cookie - cookie
 *
 * @returns {boolean}
 */
export function cookieIsExpired(cookie: Cookie | null): boolean {
  const now = new Date().getTime();

  return cookie != null && cookie.expires < now;
}
