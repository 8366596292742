// @flow strict

import { type Node, useRef, useState } from 'react';

import { ConfigAnswerDisplayTypes } from '@omq/flow/src/types/models/config';

import {
  useBackendContext,
  useEventContext,
  isMobileDevice,
  useConfigContext,
  QuestionAnswerModal,
  AnswersComponent,
  useComponentMounted,
} from '@omq/shared';

import { URLStore } from '../../stores/url-store';
import { HelpAPI, type HelpQuestion } from '../../api/help';
import { keepAutoComplete } from '../../utils/keep-auto-complete';
import { usePrintQuestion } from '@omq/shared/src/hooks/print-question';

/**
 * Type for component properties.
 */
type QuestionItemProps = {
  /**
   * Question to display.
   */
  question: HelpQuestion,

  /**
   * Flag if question is active.
   * If true, answers are displayed.
   */
  isActive: boolean,

  /**
   * Click handler for select/deselect.
   * If question is selected, question is passed as param,
   * otherwise null.
   */
  onQuestionClick: ?(question: HelpQuestion | null) => void,

  /**
   * If the question is opened in the detailed view singly
   */
  isInDetailView: boolean,
};

/**
 * Question item that is rendered inside QuestionList component.
 *
 * @param {QuestionItemProps} props - Component properties.
 *
 * @author Florian Walch
 * @since 9.2
 *
 * @returns {React.Node}
 */
export function QuestionItem(props: QuestionItemProps): Node {
  // get props
  const { question, isActive, onQuestionClick, isInDetailView } = props;

  const backend = useBackendContext();
  const pageEvent = useEventContext();
  const isMobile = isMobileDevice();
  const config = useConfigContext();

  // store focus state of link element bc
  // it is used to update the class/style of the wrapping element
  const [hasFocus, setHasFocus] = useState(false);
  const linkRef = useRef();

  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState<boolean>(false);

  // Opens the browser's print dialog and applies print specific CSS
  // to be able to print the active item
  usePrintQuestion({
    isPrintEnabled: isPrintDialogOpen,
    setIsPrintEnabled: setIsPrintDialogOpen,
    filePath: '/help/assets/print-style.css',
  });

  // scroll to activated item
  useComponentMounted(() => {
    const link = linkRef.current;
    if (isActive && link != null && link.scrollIntoView != null) {
      link.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isActive]);

  // create click handler
  const handleQuestionClick = (event: SyntheticEvent<>) => {
    event.preventDefault();

    // Do not close the question if the print button has been clicked
    if (
      onQuestionClick != null &&
      !event.target?.classList.contains('omq-question-list__print-button') &&
      !event.target?.classList.contains('omq-question-list__print-icon')
    ) {
      onQuestionClick(isActive ? null : question);
    }

    if (!isActive) {
      pageEvent.dispatchQuestionClick(question);
      HelpAPI.trackView(backend, question.id);
    }
  };

  // render content of question
  const renderQuestionContent = (): Node => {
    // Force modal if we are in mobile mode and the mobile search is also a popup,
    // that is done because in mobile it makes much more sense to have a popup,
    // because of the space we have on the small screen for a search input ->
    // Google does a similar thing
    const forceModal = isMobile && config.mobileSearchDisplayType === 'POPUP';

    // popup or force the modal mode
    const isModal = config.answerDisplayType === 'POPUP' || forceModal;

    // get answers
    const answers = (
      <AnswersComponent
        question={question}
        className="help"
        isModal={isModal}
      />
    );

    // wrap answers in a modal on mobile devices
    if (isModal) {
      const onClose = () => {
        onQuestionClick != null ? onQuestionClick(null) : undefined;

        if (linkRef.current != null) {
          linkRef.current.focus();
        }
      };

      return (
        <QuestionAnswerModal
          headline={question.body}
          className="help"
          onClose={onClose}>
          {answers}
        </QuestionAnswerModal>
      );
    }

    // render answers without modal.
    return answers;
  };

  // create class names
  let className = config.generateClassName('question-list__question');
  className += isActive
    ? ` ${config.generateClassName('question-list__question--active')}`
    : '';

  className += isInDetailView
    ? ` ${config.generateClassName('question-list__question--detail-view')}`
    : '';

  className += hasFocus
    ? ` ${config.generateClassName('question-list__question--focus')}`
    : '';

  const isInline = config.answerDisplayType === ConfigAnswerDisplayTypes.INLINE;

  // Rotate the toggle icon: For popup, it points to the right,
  // for inline, it points to the bottom when closed and to the top when opened
  let toggleIconClassName = config.generateClassName(
    'question-list__toggle-icon',
  );

  if (isInline) {
    toggleIconClassName += isActive ?
    ` ${config.generateClassName('question-list__toggle-icon--up')}` :
    ` ${config.generateClassName('question-list__toggle-icon--down')}`;
  }

  const { body, url, id } = question;

  // Determine if the print button should be displayed in the question's header bar.
  // Only for the inline variant here, otherwise the icon will appear in the bar
  // in the background of the modal. The modal already has it in its own header bar.
  const displayPrintButton = config.isPrintingActive && isActive && isInline;

  // Print button component as a variable to reuse
  const printButton = (
    <button
      onClick={() => setIsPrintDialogOpen(true)}
      title={config.loc('print')}
      className={config.generateClassName('question-list__print-button')}>
      <i className={config.generateClassName('question-list__print-icon')} />
    </button>
  );

  // render question
  return (
    <div className={className}>
      {isInDetailView ? (
        <span className={config.generateClassName('question-list__link')}>
          <div className={config.generateClassName('question-list__print-icon-container--detail-view')}>
            {body}
            {displayPrintButton && printButton}
          </div>
        </span>
      ) : (
        <a
          href={URLStore.buildAbsolutePath(url)}
          title={body}
          className={config.generateClassName('question-list__link')}
          role="button"
          onMouseDown={(evt) =>
            keepAutoComplete(
              evt,
              `.${config.generateClassName('search__input')}`,
            )
          }
          aria-controls={`aria-answers-${id}`}
          id={`aria-question-${id}`}
          aria-expanded={isActive}
          ref={linkRef}
          onClick={handleQuestionClick}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}>
          <h3>{body}</h3>
          {displayPrintButton && printButton}
          <span className={toggleIconClassName} />
        </a>
      )}

      {isActive && renderQuestionContent()}
    </div>
  );
}
