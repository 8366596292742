// @flow strict

import { type Node } from 'react';

import { useConfigContext } from '@omq/shared';

import { Navigation } from './navigation';

/**
 * Type for component properties.
 */
type QuestionNavigationProps = {
  title: string,
  onRootClick: (item: null) => void,
};

/**
 * Render navigation with root item and a single question.
 *
 * @param {QuestionNavigationProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function QuestionNavigation(props: QuestionNavigationProps): Node {
  const { title, onRootClick } = props;

  const config = useConfigContext();

  // link for root item
  const href = '/';
  const items = [
    {
      id: 0,
      href: href,
      name: config.loc('navigation_root'),
    },
    {
      id: 1,
      href: null,
      name: title,
    },
  ];

  const onItemClick = () => {
    onRootClick(null);
  };

  return (
    <Navigation
      items={items}
      onItemClick={onItemClick}
      styleName="question-navigation"
    />
  );
}
