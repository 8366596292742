// @flow strict

import { useEffect, useReducer } from 'react';

import { Form } from '../../../index';
import { uniqueId } from '../../utils/helper';

const ID_ATTRIBUTE_NAME = 'data-omqautomator';

/**
 * Initialize all forms included in content element.
 *
 * @param content - content that includes the forms
 * @param formSubmitted - callback for submitted forms
 *
 * @returns {*}
 */
function initializeForms(content: ?HTMLElement, formSubmitted?: () => void) {
  // get elements
  const formElements = content?.querySelectorAll('form') || [];

  // handle server response after submit
  const resultHandler = (data, response, formId) => {
    // get submitted form
    const form = content?.querySelector(`form#${formId}`);

    if (form != null) {
      // insert server response
      form.insertAdjacentHTML('afterend', response);

      // remove form
      form.remove();

      if (formSubmitted != null) {
        formSubmitted();
      }
    }
  };

  // add form handlers
  const reducer = (data: Array<Form>, form: HTMLElement) => {
    if (form instanceof HTMLFormElement) {
      if (form.id == null || form.id === '') {
        form.id = uniqueId('omq-answer-form-');
      }

      data.push(new Form(form, true, ID_ATTRIBUTE_NAME, resultHandler));
    }

    return data;
  };

  return Array.from(formElements).reduce(reducer, []);
}

/**
 * Custom hook to handle forms in answer items.
 *
 * @param ref - reference to component DOM element.
 */
export const useFormHandler = (ref: { current: HTMLElement | null }) => {
  const [refreshValue, forceRefresh] = useReducer((x) => x + 1, 0);

  // init form handlers in effect
  useEffect(() => {
    const forms = initializeForms(ref.current, forceRefresh);

    // clean up
    return () => {
      forms.forEach((form) => form.unsubscribe());
    };
  }, [ref, refreshValue]);
};
