// @flow strict

/**
 * Wrapper for media query list operations.
 *
 * @class
 * @since 9.2
 * @author Florian Walch
 */
export class MediaQueryWrapper {

  /**
   * Media Query List object
   */
  mediaQueryList: MediaQueryList;

  /**
   * Stored handler for break point changes
   */
  breakpointHandler: () => void;

  /**
   * Constructor.
   *
   * @param {string} breakpoint - media query to detect changes
   * @param {Function} callback - callback function
   */
  constructor(breakpoint: string, callback: () => void) {
    // create media query object
    this.mediaQueryList = window.matchMedia(breakpoint);

    // store handler
    this.breakpointHandler = callback;

    // check for older browsers, which do not have addEventListener
    if (this.mediaQueryList.addEventListener === undefined) {
      // add listener
      this.mediaQueryList.addListener(callback);
      return;
    }

    // add event listener
    this.mediaQueryList.addEventListener('change', callback);
  }

  /**
   * Remove listener from media query list
   */
  removeListener() {
    // check if media query list exists
    if (this.mediaQueryList && this.breakpointHandler) {
      // check for backwards compatibility
      if (this.mediaQueryList.removeEventListener === undefined) {
        // remove listener
        this.mediaQueryList.removeListener(this.breakpointHandler);
        return;
      }

      // remove event listener
      this.mediaQueryList.removeEventListener('change', this.breakpointHandler);
    }
  }
}
