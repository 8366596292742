// @flow strict

import {
  type Node,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';

import { useBackendContext } from '../backend/backend-wrapper';
import { useCookieSessionContext } from '../cookie-session-wrapper';
import { TrackingAPI } from '../../backend/api/tracking';

// feedback options
export const FeedBackOptions = {
  FEEDBACK_POSITIVE: 'FEEDBACK_POSITIVE',
  FEEDBACK_NEGATIVE: 'FEEDBACK_NEGATIVE',
};

// type of feedback options
export type FeedBackOption = $Keys<typeof FeedBackOptions>;

/**
 * Type for component properties.
 */
export type FeedbackWrapperProps = {
  api: {
    trackFeedback: typeof TrackingAPI.trackFeedback,
  },
  children: Node,
};

// type of context value
type FeedbackContextValue = {
  choices: Array<{ question: number, feedback: FeedBackOption }>,
  trackFeedback: (question: number, feedback: FeedBackOption) => Promise<void>,
};

// define context for feedback
export const FeedbackContext: React$Context<FeedbackContextValue | null> = createContext<FeedbackContextValue | null>(
  null,
);

// define hook to make context available to components
export function useFeedbackContext(): FeedbackContextValue {
  // get context
  const context = useContext(FeedbackContext);
  if (context == null) {
    throw new Error(
      'FeedbackContext not available. Did you forget to add FeedbackWrapper in your component tree?',
    );
  }

  return context;
}

/**
 * Context wrapper component
 *
 * @param children - wrapped components
 * @param api - api for feedback calls
 *
 * @returns {Node}
 */
export function FeedbackWrapper({ children, api }: FeedbackWrapperProps): Node {
  const backend = useBackendContext();
  const { cookie, writeCookieContent } = useCookieSessionContext();

  // get current cookie feedback content
  const cookieContent = cookie != null ? cookie.content : null;
  const defaultChoices =
    cookieContent == null ? [] : cookieContent.feedback || [];

  // define state
  const [choices, setChoices] = useState(defaultChoices);

  // update cookie when choices have been changed
  useEffect(() => {
    writeCookieContent({ feedback: choices });
    // eslint-disable-next-line
  }, [choices]);

  // update choices state
  const updateChoices = (question, feedback) => {
    // add new choice
    setChoices((current) => {
      return [
        ...current.filter((item) => item.question !== question), // remove existing choice for passed question
        { question, feedback },
      ];
    });
  };

  // function to handle server request
  const trackFeedback = async (question, feedback) => {
    // if same choice has been made before, do not send it again
    if (
      choices.find(
        (choice) =>
          choice.question === question && choice.feedback === feedback,
      ) != null
    ) {
      return;
    }

    try {
      await api.trackFeedback(backend, question, feedback);
      updateChoices(question, feedback);
    } catch (ex) {
      console.error('Error while tracking feedback', ex);
    }
  };

  // create context value object
  const contextValue = {
    choices,
    trackFeedback,
  };

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
    </FeedbackContext.Provider>
  );
}
