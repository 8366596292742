// @flow strict

const assistDSN: string = 'https://861486a35b274519b112b79800276d0f@sentry.io/1327525';

/**
 * Flag to enable/disable error tracking. Should not be enabled
 * during development
 *
 * @type {Boolean}
 */
const isEnabled: boolean = process.env.NODE_ENV === 'production';

/**
 * Flag if tracking already has been set up.
 *
 * @type {Boolean}
 * @default false
 */
let isSetup: boolean = false;

/**
 * Additional information about an error that
 * can be passed to the captureException function.
 */
export type ErrorDetails = {
  dsn?: string,
  tags?: {},
  extra?: {},
};

type SentryIntegration = { name: string };

// remove global handler, since we do not want to catch any exceptions that are not
// OMQ related
const integrationFilter = (integration: SentryIntegration) =>
  !['GlobalHandlers'].includes(integration.name);

// Sentry import
// will be imported if needed
let Sentry;

// dynamic import of sentry lib
const getGetSentry = async function getGetSentry() {
  return import(/* webpackChunkName: "sentry" */ '@sentry/browser');
};

/**
 * Error handling wrapper.
 * Wraps sentry client to send caught errors to sentry.io .
 *
 * @class
 * @author Florian Walch
 * @since 9.1
 */
export class ErrorHandling {
  /**
   * Init error handler.
   *
   * @param {*} data - Data to init error handler
   */
  static async initErrorHandler(data: ErrorDetails = { dsn: assistDSN }): Promise<void> {
    // check if error tracking should be used.
    // or if already set up
    if (!isEnabled || isSetup) {
      return;
    }

    if (Sentry == null) {
      Sentry = await getGetSentry();
    }

    // init sentry client
    Sentry.init({
      dsn: data.dsn,
      environment: process.env.NODE_ENV,
      tags: { ...data.tags },
      integrations: (integrations: Array<SentryIntegration>) =>
        integrations.filter(integrationFilter),
    });

    isSetup = true;
  }

  /**
   * Capture error, send to backend.
   *
   * @param {Error|*} error - Error to capture
   * @param {ErrorDetails} [detail] - Detailed information about the error.
   * @param {string} dsn - sentry source name
   */
  static async captureException(error: Error, detail: ?ErrorDetails, dsn?: string): Promise<void> {
    if (!isEnabled) {
      return;
    }

    if (Sentry == null) {
      Sentry = await getGetSentry();
    }

    // set up if not already happened
    if (!isSetup) {
      await ErrorHandling.initErrorHandler({
        dsn: dsn == null ? assistDSN : dsn,
      });
    }

    // if details are passed, send exception within scope
    if (detail) {
      const { tags, extra } = detail;

      Sentry.withScope(scope => {
        // set tags for scope
        if (tags) {
          Object.entries(tags)
            .forEach(([key, value]) => {
              scope.setTag(key, value);
            });
        }

        // set any additional information
        if (extra) {
          Object.entries(extra)
            .forEach(([key, value]) => {
              scope.setExtra(key, value);
            });
        }

        // send error
        Sentry.captureException(error);
      });

      return;
    }

    // send error
    await Sentry.captureException(error);
  }
}
