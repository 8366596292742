// @flow strict

import { useEffect } from 'react';

/**
 * Type for hook properties
 */
type PrintQuestionProps = {
  isPrintEnabled: boolean,
  setIsPrintEnabled: (boolean) => void,
  filePath: string,
};

/**
 * Hook that dynamically applies print styles to the DOM depending on a state variable.
 * We do that to avoid interference with the customers' own print CSS rules.
 *
 * @param isPrintEnabled Getter of the state variable that determines
 *                       if print style should be applied
 * @param setIsPrintEnabled Setter of the state variable
 * @param filePath Path to the css file for print to attach
 *
 */
export const usePrintQuestion = ({
  isPrintEnabled,
  setIsPrintEnabled,
  filePath,
}: PrintQuestionProps): void => {
  useEffect(() => {
    if (isPrintEnabled) {
      print();
    } else {
      removePrintStyles();
    }
  }, [isPrintEnabled]);

  // Loads print specific CSS and adds it dynamically to the DOM as a style element.
  const print = () => {
    fetch(filePath)
      .then((response) => response.text())
      .then((cssFileContent) => {
        applyWaitCursor();
        const styleTag = document.createElement('style');
        styleTag.textContent = cssFileContent;
        styleTag.media = 'print';
        styleTag.id = 'data-omq-print-styles';
        document.head.appendChild(styleTag);

        // SERVER-T-778: Ensure styles are applied before opening the print dialog
        // Otherwise, the content in the print preview can be cropped on slower machines.
        setTimeout(() => {
          // Opens the browser's print dialog
          window.print();

          // Gets executed after closing the print dialog (it's synchronous)
          setIsPrintEnabled(false);
          applyDefaultCursor();
        }, 3000);
      })
      .catch((error) => console.error('Error fetching CSS file:', error));
  };

  // Remove the style element from the DOM
  const removePrintStyles = () => {
    const styleElement = document.getElementById('data-omq-print-styles');

    // Ensure the styleElement and its parent exist before trying to remove it
    if (styleElement && styleElement.parentNode) {
      try {
        // Safely remove the child from the parent node
        styleElement.parentNode.removeChild(styleElement);
      } catch (e) {
        console.log('Error removing style element:', e);
      }
    }
  };

  // Set the cursor to "wait" for all UI elements
  const applyWaitCursor = () => {
    const allElements = document.querySelectorAll('*');
    allElements.forEach((element) => {
      element.style.cursor = 'wait';
    });
  };

  // Resets the cursor to the default for all UI elements
  const applyDefaultCursor = () => {
    const allElements = document.querySelectorAll('*');
    allElements.forEach((element) => {
      element.style.cursor = '';
    });
  };
};
