// @flow strict

import { type Node, useContext, createContext } from 'react';

import { cookieIsExpired, useCookie, type Cookie } from '../hooks/cookie';

/**
 * Type for component properties.
 */
type CookieSessionWrapperProps = {
  sessionId: number,
  name: string,
  cookieIsEnabled?: boolean,
  children: Node,
};

type CookieSessionContextType = {
  cookie: Cookie | null,
  checkIfSessionIsValid: () => void,
  writeCookieContent: (content: $PropertyType<Cookie, 'content'>) => void,
};

const CookieSessionContext = createContext(null);

export function useCookieSessionContext(): CookieSessionContextType {
  const context = useContext(CookieSessionContext);
  if (context == null) {
    throw new Error(
      'CookieSessionContext not available. Did you forget to add CookieSessionWrapper in your component tree?',
    );
  }

  return context;
}

/**
 * Component description.
 *
 * @param {CookieSessionWrapperProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function CookieSessionWrapper(props: CookieSessionWrapperProps): Node {
  const { sessionId, name, cookieIsEnabled, children } = props;

  const [cookie, setCookie] = useCookie(name);

  // special case
  // if cookie option has been enabled by hosting website
  // (cookie is null, but session exists)
  // then create cookie
  if (cookieIsEnabled === true && cookie == null && sessionId != null) {
    setCookie(sessionId);
  }

  // special case
  // if cookie option has been disabled by hosting website
  // (cookie is not null)
  if (cookieIsEnabled === false && cookie != null) {
    setCookie(null);
  }

  const checkIfSessionIsValid = () => {
    if (cookieIsExpired(cookie)) {
      setCookie(null);
    }
  };

  const writeCookieContent = (content) => {
    setCookie(sessionId, content);
  };

  const contextValue = {
    cookie,
    checkIfSessionIsValid,
    writeCookieContent,
  };

  return (
    <CookieSessionContext.Provider value={contextValue}>
      {children}
    </CookieSessionContext.Provider>
  );
}
