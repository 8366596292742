// @flow strict

import type { CancelToken } from 'axios';
import { Backend, TrackingAPI } from '@omq/shared';
import type { AutoCompleteItem, Question, Placeholder } from '@omq/flow';

/**
 * Type of category used in help
 */
export type HelpCategory = {
  // id of category
  id: number,

  // name of category (language specified in request)
  name: string,

  // name of icon
  iconName: string | null,

  // path of custom icon
  iconPath: string | null,

  // url path of category
  url: string,
};

/**
 * Type of question used in help
 */
export type HelpQuestion = Question & {
  // url path of question
  url: string,
};

/**
 * Type for help page
 */
export type HelpPage = {
  // categories to show in grid
  categories: Array<Array<HelpCategory>>,

  // categories to show in path
  categoryPath: Array<HelpCategory>,

  // top questions
  questions: Array<HelpQuestion>,

  // nr of next page to load
  nextPage: number,

  // language of help
  language: string,

  // google faq structured data
  faqSnippet: string | null,
};

/**
 * Params for help search
 */
export type HelpSearchParams = {
  page?: number,
  category?: string,
};

/**
 * Expected json result for search calls
 */
export type HelpSearchResult = {
  // list of questions
  questions: Array<HelpQuestion>,

  // list of auto complete items
  autoComplete: Array<AutoCompleteItem>,

  nextPage: number,
};

// default search params
const defaultSearchParams = {
  page: 0,
};

/**
 * Help API.
 *
 * Provides help related requests.
 *
 * page             => GET  /page?language=LANG&category=CATEGORY_ID
 * searchQuestions  => POST /search?SEARCH_PARAMS
 * findQuestion     => GET  /search/QUESTION_ID
 */
export const HelpAPI = {
  ...TrackingAPI,

  /**
   * Get help page for language and category.
   *
   * @param {Backend} backend - Backend object
   * @param {number|null} category - category
   * @param {number|null} page - nr of page
   * @param {Placeholder} placeholder - Placeholder for answers
   * @param {CancelToken} [cancelToken] - token to cancel request
   *
   * @returns {Promise<HelpPage>}
   */
  page: (
    backend: Backend,
    category: number | null,
    page: number,
    placeholder: Placeholder,
    cancelToken: ?CancelToken,
  ): Promise<HelpPage> => {
    // create config
    const config = {};

    // add params
    config.params = {
      category,
    };

    // add cancel token
    /* istanbul ignore else */
    if (cancelToken != null) {
      config.cancelToken = cancelToken;
    }

    let url = 'page';
    if (page !== -1) {
      url += `/${page}`;
    }

    // send request
    return backend.post<HelpPage>(url, { values: placeholder }, config);
  },

  /**
   * Search for questions.
   *
   * @param {Backend} backend - Backend object
   * @param {string} text - Text for search
   * @param {Placeholder} placeholder - Placeholder for answers
   * @param {HelpSearchParams} [params] - Request config
   * @param {CancelToken} [cancelToken] - Token to cancel request
   *
   * @returns {Promise<HelpSearchResult>}
   */
  searchQuestions: (
    backend: Backend,
    text: string,
    placeholder: Placeholder,
    /* istanbul ignore next */
    params: ?HelpSearchParams = {},
    cancelToken: ?CancelToken,
  ): Promise<HelpSearchResult> => {
    // create request data
    const data = { text, values: placeholder };

    // create request config
    const config = {};

    // add request params
    config.params = {
      ...defaultSearchParams,
      ...params,
    };

    // add cancel token
    /* istanbul ignore else */
    if (cancelToken != null) {
      config.cancelToken = cancelToken;
    }

    // send request
    return backend.post<HelpSearchResult>(`search`, data, config);
  },

  /**
   * Get a single question.
   *
   * @param {Backend} backend - Backend object
   * @param {number} id - id of the question
   * @param {Placeholder} placeholder - Placeholder for answers
   *
   * @returns {Promise<HelpQuestion>}
   */
  question: (
    backend: Backend,
    id: number,
    placeholder: Placeholder,
  ): Promise<HelpQuestion> => {
    // send request
    return backend.post<HelpQuestion>(
      `search/${id}`,
      { values: placeholder },
      {},
    );
  },
};
