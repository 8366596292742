// @flow strict

import { StrictMode, createRef, type ComponentType } from 'react';
import ReactDOM from 'react-dom';

import type { InitialData, Placeholder } from '@omq/flow';
import {
  BackendWrapper,
  ErrorBoundary,
  getBaseUrl,
  InitialDataWrapper,
  type InitialDataProps,
  EventWrapper,
  FeedbackWrapper,
} from '@omq/shared';

import { Help, type HelpRefType } from './help';
import { HelpInfo } from './components/help-info/help-info';

import {
  readConfiguration,
  updateConfiguration,
  loadStyleSheet,
  handleError,
  renderError,
  readPlaceholder,
  validatePlaceholders,
  type HelpConfiguration,
} from './utils/helper';

import type { HelpPage, HelpQuestion } from './api/help';

import { URLStore } from './stores/url-store';
import { HelpEvents } from './events/help-events';
import { HelpAPI } from './api/help';

/**
 * Type for HelpInfoParams API
 */
type HelpInfoParams = {
  account: string,
  apiKey: string,
  questionId: number | string,
  query?: string,
  cookieIsEnabled?: boolean,
  placeholder?: Placeholder,
  onClose?: () => void,
};

/**
 * Current help info element is stored here.
 *
 * @type {null}
 */
let helpInfoContainer = null;

/**
 * Type of OMQHelpInfo API
 */
declare var OMQHelpInfo: {
  open: (params: HelpInfoParams) => void,
  close: () => void,
};

/**
 * Default language for help
 *
 * @type {string}
 */
export const DEFAULT_LANGUAGE = 'EN';

const helpReference = createRef<HelpRefType>();

// create subtype for initial data of help
export type HelpInitData = InitialData & {
  page: HelpPage | null,
  question: HelpQuestion | null,
  query: string | null,
};

// cast initial data component to sub type for help
const HelpInitializer: ComponentType<
  InitialDataProps<HelpInitData>,
> = InitialDataWrapper;

/**
 * Render help component.
 */
function renderHelpComponent(): void {
  const { container, account, apiKey, cookieIsEnabled, questionId, query } = readConfiguration();

  if (account == null || apiKey == null) {
    return;
  }

  // get element
  const element = document.querySelector(container);
  if (element == null) {
    return;
  }

  const urlParams = URLStore.readURLParams();

  const placeholder = readPlaceholder();

  // render app
  ReactDOM.render(
    <StrictMode>
      <EventWrapper events={new HelpEvents(element)}>
        <ErrorBoundary onError={handleError} renderError={renderError}>
          <BackendWrapper account={account} apiKey={apiKey} path="help">
            <HelpInitializer
              placeholder={placeholder}
              params={{
                category: urlParams.category,
                question: urlParams?.question || questionId,
              }}
              cookieName={`omq-help-cookie-${apiKey}`}
              cookieIsEnabled={cookieIsEnabled}
              onReady={(result) => {
                return (
                  <Help
                    page={result.page}
                    question={result.question}
                    query={urlParams?.query || (query != null ? query : null)}
                    ref={helpReference}
                    apiKey={apiKey}
                    account={account}
                    placeholder={placeholder}
                    categories={result.config.categories}
                  />
                );
              }}
            />
          </BackendWrapper>
        </ErrorBoundary>
      </EventWrapper>
    </StrictMode>,
    element,
  );
}

/**
 * Unmount help component
 */
function unmountHelpComponent(): void {
  const { container } = readConfiguration();
  const element = document.querySelector(container);

  if (element == null) {
    return;
  }

  ReactDOM.unmountComponentAtNode(element);
}

/**
 * Provide public API.
 *
 * Update: to update config
 * Submit: Submit session
 */
function initPublicAPI(ref: { current: null | HelpRefType }) {
  window.OMQHelp = window.UserlikeHelp = {
    /**
     * Loads a question fully, were we do not have the answers for.
     *
     * @param id of the question
     * @returns found help question
     */
    loadQuestion: (id: number) => {
      return ref.current?.loadQuestion(id);
    },

    /**
     * Tracks submit for search.
     * Means nothing relevant was found.
     */
    submit: () => {
      ref.current?.submit();
    },

    /**
     * Sets custom values.
     *
     * @param customValues key-value pairs
     */
    setCustomValues: (customValues) => {
      ref.current?.setPlaceholder(validatePlaceholders(customValues));
    },

    /**
     * Sets custom values.
     * Old interface for backwards compatibility.
     *
     * @param placeholders key-value pairs
     */
    setPlaceholders: (placeholders) => {
      ref.current?.setPlaceholder(validatePlaceholders(placeholders));
    },

    update: (config: ?HelpConfiguration) => {
      if (config == null) {
        return;
      }

      // if account or api key has been changed
      // it's required to `restart` the app
      // (load style, create new session etc.)
      const unmountRequired = config.account != null || config.apiKey != null;

      // if unmount is required
      // unmount entire component, update config
      // and re-create component (will use new configuration)
      if (unmountRequired) {
        unmountHelpComponent();
        updateConfiguration(config);
        createHelp();

        return;
      }

      // update config
      updateConfiguration(config);

      // re-render component with new config
      renderHelpComponent();
    },

    mountOMQHelp: renderHelpComponent,
    unmountOMQHelp: unmountHelpComponent,
  };
}

/**
 * Create instance of OmqHelp.
 * Throws errors if the configuration is missing/wrong.
 * Add the help component to the DOM.
 */
export function createHelp(): void {
  // get properties
  const { account, apiKey } = readConfiguration();
  if (account == null || apiKey == null) {
    return;
  }

  // load styles
  loadStyleSheet(account);

  // render app
  renderHelpComponent();

  initPublicAPI(helpReference);
}

// define var for the webpack public path
let __webpack_public_path__ = null;

/**
 * Set global OMQHelpInfo API.
 *
 * Provide function to open & close question modal.
 *
 * Example:
 *   - OMQHelpInfo.open({account: 'accountName', apiKey: 'HE-1234-4567...', questionId: 1});
 *   - OMQHelpInfo.close();
 */
export function createHelpInfo() {
  window.OMQHelpInfo = window.UserlikeHelpInfo = {
    open: (params: HelpInfoParams) => {
      const { account, apiKey, questionId, placeholder = {}, onClose } = params;

      // set a webpack public path to know from where to fetch dynamic imports
      // disable any kind of inspection
      // eslint-disable-next-line
      __webpack_public_path__ = `${getBaseUrl(account) || ''}/help/`;

      try {
        OMQHelpInfo.close();

        if (account == null || account === '') {
          throw new Error('OMQHelpInfo: account is required as param.');
        }

        if (apiKey == null || apiKey === '') {
          throw new Error('OMQHelpInfo: apiKey is required as param.');
        }

        if (questionId == null || questionId === '') {
          throw new Error('OMQHelpInfo: questionId is required as param.');
        }

        // load styles
        loadStyleSheet(account, 'help-info');

        helpInfoContainer = document.createElement('div');

        // render app
        ReactDOM.render(
          <StrictMode>
            <ErrorBoundary onError={handleError} renderError={renderError}>
              <BackendWrapper account={account} apiKey={apiKey} path="help">
                <HelpInitializer
                  placeholder={placeholder}
                  params={{ question: questionId }}
                  cookieName={`omq-help-cookie-${apiKey}`}
                  cookieIsEnabled={false}
                  onReady={(result) => {
                    return (
                      <FeedbackWrapper api={HelpAPI}>
                        <HelpInfo
                          question={result.question}
                          onClose={() => {
                            OMQHelpInfo.close();
                            if (onClose != null) {
                              onClose();
                            }
                          }}
                        />
                      </FeedbackWrapper>
                    );
                  }}
                />
              </BackendWrapper>
            </ErrorBoundary>
          </StrictMode>,
          helpInfoContainer,
        );
      } catch (error) {
        // catch unhandled errors from application
        handleError(error, null);
      }
    },

    // close info popover
    close: () => {
      if (helpInfoContainer != null) {
        ReactDOM.unmountComponentAtNode(helpInfoContainer);
        helpInfoContainer = null;
      }
    },
  };
}
