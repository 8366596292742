// @flow strict

import { type Node } from 'react';

import { isMobileDevice, useConfigContext, useEventContext } from '@omq/shared';

import type { HelpCategory } from '../../api/help';
import { URLStore } from '../../stores/url-store';

import './category-grid.less';

/**
 * Type for component properties.
 */
type CategoryGridProps = {
  /**
   * Array of FAQ categories to display.
   */
  categories: Array<HelpCategory>,

  selectedCategory?: HelpCategory,

  displayType: 'large' | 'small',

  /**
   * Selection handler.
   * Selected FAQ category is passed.
   */
  onCategorySelection: (faqCategory: HelpCategory) => void,
};

/**
 * Helper function to determine how to
 * display categories in grid.
 *
 * Returns the number of columns depending on device
 * and amount of categories.
 *
 * Mobile only has 1 or 2 columns
 *
 * Grid for non-mobile:
 *   1, 2, 3, 4 -> single row
 *   5 -> 3, 2 -> first row 3 columns, second row 2 columns.
 *   6 -> 3, 3
 *   7 -> 4, 3
 *   8 -> 4, 4
 *   9 -> 3, 3, 3
 *   10 -> 4, 4, 2
 *   11 -> 4, 4, 3
 *   12 -> 4, 4 ,4
 *
 * @private
 * @param {number} count - Amount of categories
 *
 * @returns {number} - Number of columns for the grid
 */
function getColumns(count: number): number {
  // return 1 or 2 columns for mobile devices
  if (isMobileDevice()) {
    return count <= 5 ? 1 : 2;
  }

  // 1-4 items, one row
  if (count <= 4) {
    return count;
  }

  // 5,6 or 9 items have a 3 column grid
  // others have 4 columns
  // see grid in function comment.
  return [5, 6, 9].includes(count) ? 3 : 4;
}

/**
 * Render icon for category
 *
 * @param {HelpCategory} category - category of icon
 *
 * @returns {Node}
 */
function CategoryGridIcon({ category }) {
  const config = useConfigContext();
  const { iconName, iconPath } = category;

  // if category has icon name, render material icon
  if (iconName != null) {
    return (
      <i
        className={`${config.generateClassName(
          'category-grid__icon',
        )} material-icons`}
        data-icon-name={iconName}
      />
    );
  } else if (iconPath != null) {
    // otherwise set custom path in style
    const style = {
      backgroundImage: `url(${iconPath})`,
    };

    return (
      <i
        className={`${config.generateClassName(
          'category-grid__icon',
        )} custom-icons`}
        style={style}
      />
    );
  }

  return null;
}

type CategoryGridItemProps = {
  category: HelpCategory,
  onSelect: (category: HelpCategory) => void,
  isActive: boolean,
};

/**
 * Render single FAQ category grid item.
 *
 * @param {HelpCategory} category - item to render
 * @param {Function} onSelect - select handler
 * @param {boolean} isActive - active state
 *
 * @returns {Node}
 */
export function CategoryGridItem({
  category,
  onSelect,
  isActive,
}: CategoryGridItemProps): Node {
  const config = useConfigContext();
  const pageEvent = useEventContext();

  // get all information
  const { id, name, url } = category;

  // create selection handler
  const selectionHandler = (event) => {
    // stop click bubbling
    event.preventDefault();

    // send page event
    pageEvent.dispatchCategoryClick(category);

    // notify parent
    onSelect(category);
  };

  let className = config.generateClassName('category-grid__category');
  if (isActive) {
    className += ` ${config.generateClassName(
      'category-grid__category--is-active',
    )}`;
  }

  // render item
  return (
    <a
      key={id}
      href={URLStore.buildAbsolutePath(url)}
      title={name}
      className={className}
      onClick={selectionHandler}>
      <CategoryGridIcon category={category} />
      <span className={config.generateClassName('category-grid__label')}>
        {name}
      </span>
    </a>
  );
}

/**
 * Component for category grid. Display FAQ categories in a grid-like view.
 * Selection changes will be passed to the `onCategorySelection` handler, where
 * the selected FAQ category is passed, or null for deselection.
 *
 * Example usage:
 *  <CategoryGrid categories={[]}
 *                selectedCategory={ID|null}
 *                onCategorySelection={handleSelection}/>
 *
 * @author Florian Walch
 * @since 9.2
 */
export function CategoryGrid({
  categories,
  selectedCategory,
  displayType,
  onCategorySelection,
}: CategoryGridProps): Node {
  const config = useConfigContext();

  if (categories == null || categories.length === 0) {
    return null;
  }

  const columns = getColumns(categories.length);
  const displayMode = config.generateClassName(`category-grid--${displayType}`);
  const hasSelection =
    selectedCategory != null
      ? config.generateClassName('category-grid--has-selection')
      : '';

  // build class name
  const className = [
    config.generateClassName('category-grid'),
    config.generateClassName(`category-grid--columns-${columns}`),
    displayMode,
    hasSelection,
  ].join(' ');

  return (
    <div className={className}>
      {categories.map((category) => (
        <CategoryGridItem
          key={category.id}
          category={category}
          onSelect={onCategorySelection}
          isActive={
            selectedCategory != null && category.id === selectedCategory.id
          }
        />
      ))}
    </div>
  );
}
