// @flow strict

import { useState, useEffect, useRef, useReducer } from 'react';
import { CancelTokenSource } from 'axios';

import { Backend, EventDispatcher, useEventContext } from '../..';
import type { SearchDelayType } from '../..';
import { calculateSearchDelay } from '../utils/search-delay';

type SearchFuncType<R> = (cancelSource: CancelTokenSource) => Promise<R>;
type SearchResultTransformFuncType<R> = (currentResult: R, result: R) => R;

/**
 * Type for hook properties
 */
type DelayedSearchProps<T> = {
  type: SearchDelayType,
  searchValue: string,
  searchFunc: SearchFuncType<T>,
  transformResult?: SearchResultTransformFuncType<T>,
  defaultResult: T,
  forceSearch?: boolean,
};

/**
 * Hook calls search request with a delay depending on given input.
 *
 * @param {SearchDelayType} type - help or contact search
 * @param {string} searchValue - search text
 * @param {SearchFuncType<T>} searchFunc - function that calls search request
 * @param {SearchResultTransformFuncType<T>} transformResult - transform result
 * @param {T} defaultResult - default result for empty search
 * @param {boolean} [forceSearch] - send search even with empty search value
 *
 * @returns {T} - search result
 */
export const useDelayedSearch = <T>({
  type,
  searchValue,
  searchFunc,
  transformResult = (current, result) => result,
  defaultResult,
  forceSearch = false,
}: DelayedSearchProps<T>): T => {
  const [result, setResult] = useState<T>(defaultResult);

  // create refresh hook, causes a re-render for each call
  const [refreshValue, forceRefresh] = useReducer((x) => x + 1, 0);

  const eventDispatcher = useEventContext<EventDispatcher<T>>();

  // create ref for search delay timer
  const searchTimerRef = useRef<{
    isWaiting: boolean,
    cancelSource: CancelTokenSource | null,
    timer: TimeoutID | null,
    needsRefresh: boolean,
  }>({
    isWaiting: false,
    cancelSource: null,
    timer: null,
    needsRefresh: false,
  });

  const handleSearchResult = (result, transform = true) => {
    if (transform) {
      eventDispatcher.dispatchSearchLoaded(searchValue, result);
      setResult((currentResult) => transformResult(currentResult, result));
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    // reset timer & cancel request if search result is empty
    if (searchValue === '' && !forceSearch) {
      if (searchTimerRef.current.isWaiting) {
        clearTimeout(searchTimerRef.current.timer);
        searchTimerRef.current.timer = null;
        searchTimerRef.current.isWaiting = false;
        searchTimerRef.current.needsRefresh = false;
      }

      if (searchTimerRef.current.cancelSource != null) {
        searchTimerRef.current.cancelSource.cancel('Request canceled');
        searchTimerRef.current.cancelSource = null;
      }

      // set empty result
      handleSearchResult(defaultResult, false);
      return;
    }

    // do nothing is search is already running
    if (searchTimerRef.current.isWaiting) {
      // if there is a new search value, but there is already a search request
      // (waiting to be called, or request has been sent already)
      // set refresh flag, after current search request has been finished
      // search will be triggered again
      searchTimerRef.current.needsRefresh = true;
      return;
    }

    // reset flag if there is no current search request
    searchTimerRef.current.needsRefresh = false;

    // get delay for given input
    const searchDelay = calculateSearchDelay(searchValue.length, type);

    // create search function
    const performSearch = () => {

      // cancel already running search request if one exists
      if (searchTimerRef.current.cancelSource != null) {
        searchTimerRef.current.cancelSource.cancel('Request canceled');
        searchTimerRef.current.cancelSource = null;
      }

      // send search request
      searchTimerRef.current.cancelSource = Backend.getCancelToken();

      // run search & handle response
      const searchPromise = searchFunc(searchTimerRef.current.cancelSource);
      searchPromise
        .then((result) => {
          // update result
          handleSearchResult(result);
        })
        .catch((error) => {
          // handle errors
          console.error('Search request error', error);
        })
        .finally(() => {
          // reset timer
          searchTimerRef.current.isWaiting = false;
          searchTimerRef.current.cancelSource = null;
          searchTimerRef.current.timer = null;

          // if there is a new search value,
          // forceRefresh to search with latest search value
          if (searchTimerRef.current.needsRefresh) {
            forceRefresh();
          }
        });
    };

    // run search immediately, if searchDelay is 0
    if (searchDelay === 0) {
      performSearch();
    } else {
      // otherwise call search after delay
      searchTimerRef.current.isWaiting = true;
      searchTimerRef.current.timer = setTimeout(performSearch, searchDelay);
    }

    // add refresh value to dependencies, other hook won't be called (since nothing has changed).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFunc, refreshValue, forceSearch]);

  return result;
};
