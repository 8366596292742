// @flow strict

import { type Node, useContext, createContext } from 'react';

import { Backend } from '../../..';

/**
 * Type for component properties.
 */
type BackendProps = {
  account: string,
  apiKey?: string,
  path?: string,
  children: Node,
};

const BackendContext = createContext<Backend | null>(null);

export function useBackendContext(): Backend {
  const backend = useContext(BackendContext);
  if (backend == null) {
    throw new Error(
      'BackendContext is null - Did you forget to wrap your component with BackendWrapper?',
    );
  }

  return backend;
}

/**
 * Component description.
 *
 * @param {BackendProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function BackendWrapper(props: BackendProps): Node {
  const { account, apiKey, path, children } = props;
  const backend = new Backend(account, apiKey != null ? apiKey : null, path);

  return (
    <BackendContext.Provider value={backend}>
      {children}
    </BackendContext.Provider>
  );
}
