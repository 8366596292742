// @flow strict

import FormValidation from './validation';
import { CONDITION_CSS } from '../conditional-answer/conditional-answer';

/**
 * Type for form data.
 */
type FormData = {
  actionId: string,
  [key: string]: string,
};

/**
 * Type for function that handles form submit results.
 */
type FormResultHandler =
  | ((data: FormData, result: string, formId: string) => void)
  | null;

/**
 * Read file content of given file, and return as
 * Base64 string.
 *
 * @param {File} file - file to read.
 *
 * @returns {Promise<string>}
 */
async function readFileContent(file): Promise<string> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      if (fileReader.result != null) {
        resolve(fileReader.result.toString());
      }
    });

    fileReader.readAsDataURL(file);
  });
}

/**
 * Get content of each file in passed file input.
 * Returns content as base64 string, with name & mime type.
 *
 * @param {HTMLInputElement} fileInput - input element
 *
 * @returns {Promise<string>}
 */
async function readFileInputs(fileInput: HTMLInputElement): Promise<string> {
  const result = [];

  // go through all files
  for (let i = 0; i < fileInput.files.length; i++) {
    const file = fileInput.files[i];

    // read file content
    const content = await readFileContent(file);

    // add file info to result
    result.push({
      name: encodeURIComponent(file.name),
      mimeType: encodeURIComponent(file.type),
      base64: encodeURIComponent(content.substring(content.indexOf(',') + 1)),
      path: '',
    });
  }

  return result
    .map(
      (item) =>
        `name=${item.name}&mimeType=${item.mimeType}&base64=${item.base64}&path=${item.path}`,
    )
    .join('&');
}

/**
 * Read values from form elements/inputs.
 *
 * @param {Array<HTMLElement>} elements - form elements
 * @param {FormData} formData - initial data
 *
 * @returns {Promise<FormData>}
 */
async function readFormElementValues(
  elements: Array<HTMLElement>,
  formData: FormData,
): Promise<FormData> {
  const result = { ...formData };

  // loop through all form elements
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    // only get values of elements that  are user input elements.
    if (
      !(
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element instanceof HTMLSelectElement
      )
    ) {
      continue;
    }

    // only elements with valid names.
    // and elements that are marked as active
    if (element.name === '' || element.disabled) {
      continue;
    }

    // if element is a file input, read file content of each file
    if (element instanceof HTMLInputElement && element.type === 'file') {
      result[element.name] = await readFileInputs(element);
      continue;
    }

    // store value of form element
    result[element.name] = element.value;
  }

  return result;
}

/**
 * Handle automator run result.
 * Notify embedding client about form submit. (e.g. close ticket)
 *
 * @param {FormData} data - Form data
 * @param {string} result - server result
 * @param {string} formId - id of form
 */
function handleActionSuccess(
  data: FormData,
  result: string,
  formId: string,
): void {
  // if form handler is the same window, call directly
  if (window.formSubmitted) {
    window.formSubmitted(data, result, formId);
    return;
  }

  // post to other window
  const message = { ...data, result, method: 'formSubmitted' };

  // send data to iFrame holder (allow all origins, since clients can have different ip's, ports etc. )
  window.postMessage(JSON.stringify(message), '*');
}

/**
 * Handle form errors.
 * Notify client about issue.
 *
 * @param {FormData} data - Form data
 * @param {string} result - server result
 * @param {string} formId - id of form
 */
function handleActionError(
  data: FormData,
  result: string,
  formId: string,
): void {
  if (window.formSubmitError) {
    window.formSubmitError(data, result, formId);
    return;
  }

  // post to other window
  const message = { ...data, result, method: 'formSubmitError' };

  // send data to iFrame holder (allow all origins, since clients can have different ip's, ports etc. )
  window.postMessage(JSON.stringify(message), '*');
}

/**
 * Enable all elements of the passed form.
 *
 * @param {HTMLFormElement} form - form to enable
 */
function enableForm(form: HTMLFormElement): void {
  // get elements
  const formElements = Array.from(form.elements);

  // remove disabled attribute from elements
  formElements.forEach((formElement) =>
    formElement.removeAttribute('disabled'),
  );

  hideLoader(form);
}

/**
 * Disable all elements of the passed form.
 *
 * @param {HTMLFormElement} form - form to disable
 */
function disableForm(form: HTMLFormElement): void {
  // get elements
  const formElements = Array.from(form.elements);

  // set all elements disabled attribute
  formElements.forEach((formElement) =>
    formElement.setAttribute('disabled', 'disabled'),
  );

  showLoader(form);
}

/**
 * Display loading indicator to let user know sth is happening.
 *
 * @param form - form where to show the loader
 */
function showLoader(form: HTMLFormElement): void {
  const loader = document.createElement('div');
  loader.classList.add('omq-form-loader');

  const loaderIcon = document.createElement('div');
  loaderIcon.classList.add('omq-loader-icon');

  // just add empty elements,
  // styles is located in question-answer.core.css
  loader.appendChild(loaderIcon);
  form.appendChild(loader);
}

/**
 * Remove loading indicator.
 *
 * @param form - form where the loader should be removed.
 */
function hideLoader(form: HTMLFormElement): void {
  const loader = form.querySelector('.omq-form-loader');

  if (loader != null) {
    loader.remove();
  }
}

/**
 * Class that handle form submits.
 */
export default class Form {
  /**
   * Form DOM element.
   *
   * @type {HTMLFormElement}
   */
  form: ?HTMLFormElement = null;

  /**
   * Validator
   *
   * @type {FormValidation}
   */
  validator: ?FormValidation = null;

  idAttributeName: string = 'data-omqaction';

  resultHandler: FormResultHandler | null = null;

  /**
   * Enabled form elements (input, textarea, select etc.) of given
   * HTMLElement.
   *
   * Add required attr of element is marked as required (via REQUIRED_ATTR)
   * and also add ACTIVE_FIELD_CLASS_NAME to element, which will add the value
   * to the form data that will be sent to the server on submit.
   *
   * @param {HTMLElement} conditionNode
   */
  static enableFormElementsForConditionNode(conditionNode: HTMLElement) {
    // get the form
    const form = conditionNode.closest('form');
    if (form == null || !(form instanceof HTMLFormElement)) {
      // if condition is not part of a form, there is nothing to do.
      return;
    }

    // go through form elements
    Array.from(form.elements).forEach((element) => {
      // only handle elements that are part of the given condition
      if (element.closest(`.${CONDITION_CSS.NODE}`) === conditionNode) {
        element.removeAttribute('disabled');
      }
    });
  }

  /**
   * Disable form elements (input, textarea, select etc.) of given
   * HTMLElement.
   *
   * Remove required attr, to prevent form validation for hidden elements.
   * And remove ACTIVE_FIELD_CLASS_NAME from all elements, to exclude them
   * from server request on submit.
   *
   * @param {HTMLElement} condition
   */
  static disableFormElementForCondition(condition: HTMLElement) {
    // get form element
    const form = condition.closest('form');
    if (form == null || !(form instanceof HTMLFormElement)) {
      // if condition is not part of a form, there is nothing to do.
      return;
    }

    // go through form elements
    Array.from(form.elements).forEach((element) => {
      // do not disable value field for given condition
      if (
        element instanceof HTMLInputElement &&
        element.type === 'hidden' &&
        element.parentElement === condition
      ) {
        return;
      }

      // handle element only if it's a child of the given condition
      if (condition.contains(element)) {
        element.setAttribute('disabled', 'true');
      }
    });
  }

  /**
   * Constructor.
   *
   * @param form - HTML form element
   * @param validationEnabled - enable/disable validation
   * @param idAttributeName - name of attribute that has the id for the action
   * @param resultHandler - optional result handler
   */
  constructor(
    form: HTMLFormElement,
    validationEnabled: boolean,
    idAttributeName: ?string,
    resultHandler?: FormResultHandler,
  ) {
    this.form = form;

    // add validator if validation is enabled
    if (validationEnabled) {
      // set noValidate to true
      // this will prevent validation before submit
      // bc validation is being done during submit bc of
      // some custom validation
      // $FlowExpectedError - noValidate is missing in HTMLFormElement declaration
      form.noValidate = true;

      // create validator instance for form
      this.validator = new FormValidation(form);
    }

    // add custom id attribute name
    if (idAttributeName != null) {
      this.idAttributeName = idAttributeName;
    }

    if (resultHandler != null) {
      this.resultHandler = resultHandler;
    }

    // add submit handler
    form.addEventListener('submit', this.handleFormSubmit);
  }

  /**
   * Get attribute value for given name.
   *
   * @param attributeName - name of attribute
   *
   * @returns {string|null|undefined}
   */
  getFormAttribute(attributeName: string): ?string {
    return this.form
      ? this.form.getAttribute(attributeName)
      : /* istanbul ignore next*/ null;
  }

  /**
   * Handle form submits. Send data to server via json api.
   *
   * @param {Event} event - Submit event
   */
  handleFormSubmit: (event: Event) => Promise<void> = async (
    event,
  ): Promise<void> => {
    const { form, validator } = this;

    // stop form submit
    event.preventDefault();

    if (form == null) {
      return;
    }

    // validate form
    if (validator != null && !validator.validate()) {
      // if form is invalid
      // scroll to first invalid element
      const firstInvalidElement = form.querySelector('.invalid');
      if (firstInvalidElement != null) {
        firstInvalidElement.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }

      return;
    }

    // get form elements
    const elements = Array.from(form.elements);

    // get form info
    let action = this.getFormAttribute('action');
    const actionId = this.getFormAttribute(this.idAttributeName);

    if (action == null || actionId == null) {
      return;
    }

    // generate form data for request
    const formData: FormData = await readFormElementValues(elements, {
      actionId,
    });

    // create request data object
    const requestData = { ...formData, ...window.metaData };

    disableForm(form);

    // create request
    const httpRequest = new XMLHttpRequest();
    httpRequest.onload = () => {
      enableForm(form);

      // if a custom handler is given
      if (this.resultHandler != null) {
        // use custom handler
        this.resultHandler(requestData, httpRequest.response, form.id);
        return;
      }

      // or use global handlers
      if ([200, 204].includes(httpRequest.status)) {
        handleActionSuccess(requestData, httpRequest.response, form.id);
      } else {
        // handle error
        handleActionError(requestData, httpRequest.response, form.id);
      }
    };

    // handle request errors (network errors)
    // server errors are handled in onload event
    httpRequest.onerror = () => {
      enableForm(form);

      // if a custom handler is given
      if (this.resultHandler != null) {
        // use custom handler
        this.resultHandler(requestData, httpRequest.response, form.id);
      } else {
        handleActionError(requestData, httpRequest.response, form.id);
      }
    };

    httpRequest.open('POST', action);
    httpRequest.setRequestHeader(
      'Content-Type',
      'application/json;charset=UTF-8',
    );

    // send request
    httpRequest.send(JSON.stringify(requestData));
  };

  /**
   * Remove event listener for submit & validation
   */
  unsubscribe() {
    if (this.form != null) {
      this.form.removeEventListener('submit', this.handleFormSubmit);
    }

    if (this.validator != null) {
      this.validator.unsubscribeElements();
    }
  }
}
