// @flow strict

import { useEffect } from 'react';

import { ConfigURLPatternTypes, type ConfigURLPatternType } from '@omq/flow';
import { useConfigContext } from '@omq/shared';

import {
  URLStore,
  type URLChangeCallback,
  type HelpURLParams,
} from '../stores/url-store';

type UpdateURLFuncType = (
  url: string,
  urlPatternType?: ConfigURLPatternType,
) => void;

/**
 * Hook to handle URL param changes.
 *
 * @param {URLChangeCallback} changeHandler - callback handler for url param changes.
 *
 * @returns {[HelpURLParams, (params: HelpURLParams) => void]}
 */
export const useURLParams: (
  changeHandler?: URLChangeCallback,
) => [HelpURLParams, UpdateURLFuncType] = changeHandler => {
  const config = useConfigContext();
  // read current values
  const urlParams = URLStore.readURLParams(config.urlPatternType);

  // add url change listener
  useEffect(() => {
    if (changeHandler == null) {
      return;
    }

    // subscribe
    const urlChangeHandler = URLStore.registerURLParamChanges(changeHandler);

    // unsubscribe
    return () => {
      URLStore.unregisterURLParamChanges(urlChangeHandler);
    };
  }, [changeHandler]);

  // create update function
  const updateURL = (
    url: string,
    urlPatternType: ConfigURLPatternType = ConfigURLPatternTypes.QUERY_PARAM,
  ) => {
    URLStore.writeURL(url, urlPatternType);
  };

  // return params & update function
  return [urlParams, updateURL];
};
