// @flow strict

import { Fragment, type Node, useRef } from 'react';

import type { Question } from '@omq/flow';

import { useFormHandler } from './form';
import { useConditionalAnswer } from './conditional-answer';
import { Feedback } from '../../components/feedback/feedback';
import { useConfigContext } from '../../components/config-wrapper';
import { useAnswerCodeEditor } from './code-editor';

/**
 * Renders a single answer element.
 * Add hooks to support interactive elements like forms.
 *
 * @param {string} html - html of answer
 *
 * @returns {Node}
 */
const AnswerComponent = ({ html }: { html: string }) => {
  const config = useConfigContext();

  // create ref to keep reference to the DOM element.
  const ref = useRef<HTMLElement | null>(null);

  // run hook to add form event handlers
  useFormHandler(ref);
  useConditionalAnswer(ref);
  useAnswerCodeEditor(ref);

  const markup = { __html: html };

  // render answer item
  return (
    <div
      ref={ref}
      className={config.generateClassName('question-list__answer')}
      dangerouslySetInnerHTML={markup}
    />
  );
};

type AnswersComponentProps = {
  question: Question,
  className: string,
  isModal: boolean,
};

/**
 * Render a list of answers or nothing.
 *
 * @param {Question} question - question with answers to render
 * @param {string} className - css identifier for elements
 * @param {boolean} [isModal] - if true, modal class names are included
 *
 * @returns {Node}
 */
export function AnswersComponent({
  question: { id, answers },
  className,
  isModal = false,
}: AnswersComponentProps): Node {
  const config = useConfigContext();

  // render nothing
  if (answers.length === 0) {
    return null;
  }

  let listClassNames = config.generateClassName('question-list__answers');
  if (isModal) {
    listClassNames += ` ${config.generateClassName('popover__answers')}`;
    listClassNames += ` ${config.generateClassName(
      `${className}-popover__answers`,
    )}`;
  }

  // render list of answers
  return (
    <Fragment>
      <div
        className={listClassNames}
        aria-labelledby={`aria-question-${id}`}
        id={`aria-answers-${id}`}
        role="region"
        tabIndex="-1">
        {answers.map((answer) => (
          <AnswerComponent key={answer.id} html={answer.document.html || ''} />
        ))}
      </div>

      {<Feedback question={id} />}
    </Fragment>
  );
}
