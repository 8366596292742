// @flow strict

import { useEffect } from 'react';

import type { HelpPage } from '../api/help';

const SCRIPT_ELEMENT_ID = 'omq-faq-structured-data';

/**
 * Add FAQ structured data to head of embedding page.
 * @see https://developers.google.com/search/docs/data-types/faqpage
 *
 * If passed helpPage has a faqSnippet, create/update script tag in page header.
 * Otherwise remove script / do nothing.
 *
 * @param helpPage
 */
export function useFaqStructuredData(helpPage: HelpPage): void {
  useEffect(() => {
    const faqSnippetElement = document.getElementById(SCRIPT_ELEMENT_ID);
    const faqSnippet = helpPage.faqSnippet;

    // if there is no data
    // remove data from page
    if (faqSnippet == null) {
      if (faqSnippetElement != null) {
        faqSnippetElement.remove();
      }

      return;
    }

    // if structured data element does not exist
    // create & append to head
    if (faqSnippetElement == null) {
      // create script tag
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.id = SCRIPT_ELEMENT_ID;
      script.innerText = faqSnippet;

      // append script to head
      const head = document.querySelector('head');
      if (head != null) {
        head.appendChild(script);
      }
    } else {
      // update script tag
      faqSnippetElement.innerText = faqSnippet;
    }
  }, [helpPage.faqSnippet]);
}
