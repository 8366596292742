// @flow strict

import { Backend } from '../backend';
import type { FeedBackOption } from '../../components/feedback/feedback-context';

/**
 * Collection of tracking requests.
 */
export const TrackingAPI = {
  /**
   * Send tracking request.
   *
   * @param {Backend} backend - Backend object
   * @param {number} question - id of question to track
   *
   * @returns {Promise<void>}
   */
  trackView: (backend: Backend, question: number): Promise<void> => {
    return backend.post('view', { question });
  },

  /**
   * Send submit tracking request.
   *
   * @param {Backend} backend - Backend object
   *
   * @returns {Promise<void>}
   */
  trackSubmit: (backend: Backend): Promise<void> => {
    return backend.post('submit');
  },

  /**
   * Send feedback tracking request.
   *
   * @param {Backend} backend - Backend object
   * @param {number} question - id of question
   * @param {FeedBackOption} feedback - feedback options
   *
   * @returns {Promise<void>}
   */
  trackFeedback: (
    backend: Backend,
    question: number,
    feedback: FeedBackOption,
  ): Promise<void> => {
    return backend.post('feedback', null, { params: { question, feedback } });
  },
};
