// @flow strict

import { useEffect } from 'react';

import { initConditionalAnswers } from '../../conditional-answer/conditional-answer';

/**
 * Handle changes of conditional answers, and show/hide
 * selected content.
 *
 * @param answerRef - reference of html of answer
 */
export const useConditionalAnswer = (answerRef: {
  current: HTMLElement | null,
}) => {
  useEffect(() => {
    if (answerRef.current == null) {
      return;
    }

    const unsubscribe = initConditionalAnswers(answerRef.current);

    // remove event listener on clean up
    return () => {
      unsubscribe();
    };
  }, [answerRef]);
};
