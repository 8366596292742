// @flow strict

import { Fragment, useLayoutEffect, useRef, useEffect, type Node } from 'react';
import ReactDOM from 'react-dom';

import { useConfigContext } from '../config-wrapper';

import './modal.less';

type ModalProps = {
  container: string,
  children: Node,
  onClose: () => void,

  styleName?: string,
};

/**
 * Create container for modal component.
 *
 * @param {string} selector - Additional class to add to container
 * @returns {HTMLDivElement}
 */
function createModalContainer(selector: string = 'omq'): HTMLElement {
  const container = document.createElement('div');
  container.classList.add(selector);

  return container;
}

/**
 * Prevent background from scrolling while modal is visible.
 *
 * @param {string} className - class name of body scroll lock
 *
 * @returns {number}
 */
function lockBodyScroll(className: string): number {
  const body = document.body;
  if (document.documentElement == null || body == null) {
    return 0;
  }

  // get current scroll position, since position change of body
  // will reset scroll position.
  const scrollTop = document.documentElement.scrollTop || body.scrollTop;

  // change body style to fixed, makes it unable to scroll
  // set inverted top position, to show background at same position
  body.classList.add(className);
  body.style.top = `-${scrollTop}px`;

  // return scrollTop for later use
  return scrollTop;
}

/**
 * Revert changes made by lockBodyScroll.
 *
 * @param {string} className - class name of body scroll lock
 * @param {number} scrollTop - Position to restore body scroll
 */
function unlockBodyScroll(className: string, scrollTop: number): void {
  const body = document.body;
  if (body == null) {
    return;
  }

  // restore body style
  body.classList.remove(className);
  body.style.top = '0px';

  // restore scroll position
  body.scrollTop = scrollTop;

  if (document.documentElement != null) {
    document.documentElement.scrollTop = scrollTop;
  }
}

/**
 * Hook to prepare and remove modal container.
 *
 * @param {string} selector
 *
 * @returns {HTMLElement}
 */
function useModalContainer(selector: string): HTMLElement {
  const { generateClassName, cssIdentifier } = useConfigContext();
  // create container & reference
  const modalRef = useRef(createModalContainer(cssIdentifier));
  const modalWrapper = modalRef.current;

  useLayoutEffect(() => {
    const body = document.body;
    let currentBodyScrollPosition;

    // add wrapper to document
    // and lock scrolling
    if (body != null) {
      body.appendChild(modalWrapper);
      currentBodyScrollPosition = lockBodyScroll(
        generateClassName('modal-open'),
      );
    }

    // remove wrapper
    // and unlock scrolling when component will be removed
    return () => {
      if (body != null) {
        body.removeChild(modalWrapper);
        unlockBodyScroll(
          generateClassName('modal-open'),
          currentBodyScrollPosition,
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalWrapper]);

  return modalWrapper;
}

/**
 * Modal component.
 * Displays children in a modal window and disables the
 * background page.
 *
 * @param {ModalProps} props - Component properties
 *
 * @returns {Node}
 */
export function Modal(props: ModalProps): Node {
  const { container, onClose, styleName, children } = props;
  const { generateClassName } = useConfigContext();
  const modalWrapper = useModalContainer(container);

  const classNames = generateClassName(
    `modal__panel ${styleName || ''} notranslate`,
  );
  const contentRef = useRef();

  // handle key events & close on escape
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.which === 27 || event.key === 'Escape') {
      onClose();
    }
  };

  // focus modal content to allow close via escape
  useEffect(() => {
    if (contentRef.current != null) {
      contentRef.current.focus();
    }
  }, []);

  return ReactDOM.createPortal(
    <Fragment>
      <div className={generateClassName('modal')} onClick={onClose} />
      <div
        className={classNames}
        ref={contentRef}
        translate="no"
        onKeyUp={onKeyUp}
        tabIndex="-1">
        {children}
      </div>
    </Fragment>,
    modalWrapper,
  );
}
