// @flow strict

import { useEffect, useRef } from 'react';

/**
 * Wrap useEffect into a hook, to only call passed function
 * on re-renders.
 *
 * @param fn - effect to run
 * @param deps - hook dependencies
 */
export const useComponentMounted = (fn: () => void | *, deps: Array<*>) => {
  const componentMounted = useRef(false);

  useEffect(() => {
    if (componentMounted.current) {
      return fn();
    } else {
      componentMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
