// @flow strict

import { type Node } from 'react';

import './footer.less';

// type of component props
type FooterProps = {
  productLink: string,
  productName: string,

  // optional link click handler
  // e.g. to prevent blur events
  onLinkMouseDown?: (event: MouseEvent) => void,
};

/**
 * Help footer.
 *
 * @author Florian Walch
 * @since 9.4
 *
 * @returns {Node}
 */
export function Footer(props: FooterProps): Node {
  const { productLink, productName, onLinkMouseDown } = props;

  return (
    <p className="omq-footer">
      <span className="omq-footer__text">{productName} by </span>
      <a
        href={productLink}
        onMouseDown={onLinkMouseDown}
        title="AI based customer service software"
        className="omq-footer__link"
        target="_blank"
        rel="noopener noreferrer">
        OMQ
      </a>
    </p>
  );
}
