// @flow strict

import {
  forwardRef,
  type Node,
  type AbstractComponent,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import {
  FeedbackWrapper,
  Footer,
  useBackendContext,
  useConfigContext,
  useCookieSessionContext,
  useEventContext,
} from '@omq/shared';
import type { FaqCategory, Placeholder } from '@omq/flow';

import { useMediaQuery } from './hooks/media-query';
import { useURLParams } from './hooks/url-params';
import { DetailView } from './views/detail-view';
import { HelpView } from './views/help-view';
import { HelpAPI } from './api/help';
import type { HelpPage, HelpQuestion } from './api/help';
import { keepAutoComplete } from './utils/keep-auto-complete';

import './help.less';

/**
 * Props for the component.
 */
type HelpProps = {
  page: HelpPage | null,
  question: HelpQuestion | null,
  query: string | null,
  account: string,
  apiKey: string,
  placeholder: Placeholder,
  categories: Array<FaqCategory>,
};

/**
 * Main Help component.
 *
 * Holds app state and
 * renders views depending on current app state.
 *
 * @param {HelpProps} props - Component properties
 * @param {{}} ref - Reference to component
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
function Help(props: HelpProps, ref): Node {
  const {
    page,
    question,
    query,
    account,
    apiKey,
    placeholder: defaultPlaceholder,
    categories,
  } = props;

  const backend = useBackendContext();
  const config = useConfigContext();

  const { checkIfSessionIsValid } = useCookieSessionContext();
  checkIfSessionIsValid();

  // store search value & focus state between detail view & help view
  const [keepFocus, setKeepFocus] = useState<boolean>(false);

  const [initialSearchValue, setInitialSearchValue] = useState<string>(query || '');
  const [placeholder, setPlaceholder] = useState<Placeholder>(
    defaultPlaceholder,
  );

  const [
    detailViewQuestion,
    setDetailViewQuestion,
  ] = useState<HelpQuestion | null>(question);

  // get urlParams
  const [, updateURL] = useURLParams();

  // fire help categories loaded event
  const pageEvent = useEventContext();
  useEffect(() => {
    pageEvent.dispatchCategoriesLoaded(categories);
  }, [categories]);

  // setup re-render for resize
  useMediaQuery();

  // create submit callback
  const submitHandler = useCallback(() => {
    HelpAPI.trackSubmit(backend);
  }, [backend]);

  // create load question callback
  const loadQuestion = useCallback((id: number) => {
    return HelpAPI.question(backend, id, placeholder);
  }, [backend]);

  // create reference api
  useImperativeHandle(ref, () => ({
    loadQuestion,
    submit: submitHandler,
    setPlaceholder,
  }));

  // clear url - remove all params
  const clearURL = () => {
    updateURL('/', config.urlPatternType);
  };

  let contentView;

  if (detailViewQuestion != null) {
    contentView = (
      <DetailView
        question={detailViewQuestion}
        onSearchChange={(value) => {
          // store input value for help view
          setInitialSearchValue(value);
          setKeepFocus(true);

          // reset current question
          setDetailViewQuestion(null);
          clearURL();
        }}
        onRootClick={() => {
          // reset current question
          setDetailViewQuestion(null);
          clearURL();
        }}
        placeholder={placeholder}
      />
    );
  }
  else {
    contentView = (
      <HelpView
        initialHelpPage={page}
        initialSearchValue={initialSearchValue}
        keepFocus={keepFocus}
        placeholder={placeholder}
      />
    );
  }

  return (
    <FeedbackWrapper api={HelpAPI}>
      <div
        className={`${config.generateClassName('help')} notranslate`}
        translate="no">
        {contentView}

        {config.isPoweredByActive && (
          <Footer
            productLink={`https://omq.ai/products/help/?utm_source=help&utm_medium=product&utm_campaign=${account}&utm_content=${apiKey}`}
            productName="Help Page software"
            onLinkMouseDown={(evt) =>
              keepAutoComplete(evt, config.generateClassName('search__input'))
            }
          />
        )}
      </div>
    </FeedbackWrapper>
  );
}

export type HelpRefType = {
  loadQuestion: (id: number) => Promise<HelpQuestion>,
  submit: () => void,
  setPlaceholder: (placeholder: Placeholder) => void,
};

const ForwardHelp: AbstractComponent<HelpProps, HelpRefType> = forwardRef<
  HelpProps,
  HelpRefType,
>(Help);
export { ForwardHelp as Help };
