// @flow strict

import { type Node, createContext, useContext } from 'react';
import { EventDispatcher } from '../event/event-dispatcher';

/**
 * Type for component properties.
 */
type EventWrapperProps<E: EventDispatcher<*>> = {
  children: Node,
  events: EventDispatcher<*>,
};

const EventContext = createContext<OMQSubType<EventDispatcher<*>>>(
  new EventDispatcher(document),
);

/**
 * Context helper function.
 * Used by components to get app event context.
 *
 * @returns {EventContext}
 */
export function useEventContext<T: EventDispatcher<*>>(): T {
  return useContext<T>(EventContext);
}

/**
 * Render context provider for event context.
 *
 * @param {EventWrapperProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function EventWrapper<E: EventDispatcher<*>>(
  props: EventWrapperProps<E>,
): Node {
  const { children, events } = props;

  return (
    <EventContext.Provider value={events}>{children}</EventContext.Provider>
  );
}
