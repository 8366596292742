// @flow strict

import { type Node, useContext, createContext } from 'react';

import {
  type Config,
  ConfigAnswerDisplayTypes,
  ConfigCategoryDisplayTypes,
  ConfigMobileSearchDisplayTypes,
  ConfigURLPatternTypes,
} from '@omq/flow';
import { formatString } from '../utils/helper';

/**
 * Type for component properties.
 */
type ConfigWrapperProps = {
  config: Config,
  children: Node,
};

/**
 * Type of context value.
 */
type ConfigContextValue = Config & {
  loc: (key: string, ...params: Array<string>) => string,
  generateClassName: (className: string) => string,
};

const defaultConfig = {
  cssIdentifier: 'omq',
  messages: {},
  isAutoFocusActive: true,
  isDefaultCookieActive: false,
  isFeedbackActive: false,
  isPrintingActive: false,
  isPoweredByActive: true,
  answerDisplayType: ConfigAnswerDisplayTypes.INLINE,
  categoryDisplayType: ConfigCategoryDisplayTypes.DISAPPEAR,
  mobileSearchDisplayType: ConfigMobileSearchDisplayTypes.POPUP,
  urlPatternType: ConfigURLPatternTypes.QUERY_PARAM,
  categories: [],
};

// define context for messages
export const ConfigContext: React$Context<ConfigContextValue | null> = createContext<ConfigContextValue | null>(
  null,
);

// define hook to make context available to components
export function useConfigContext(): ConfigContextValue {
  // get context
  const context = useContext(ConfigContext);
  if (context == null) {
    return createConfigContextValue(defaultConfig);
  }

  return context;
}

/**
 * Create context value.
 *
 * @param {Config} config - loaded config.
 *
 * @returns {ConfigContext}
 */
export function createConfigContextValue(config: Config): ConfigContextValue {
  const result = {
    ...defaultConfig,
    ...config,
  };

  return {
    ...result,
    /**
     * Get translated string for passed key.
     * Replace passed params of messages value.
     *
     * @param {string} key - Key of message
     * @param {Array<string>} params - Values to replace in string
     *
     * @returns {string}
     */
    loc: (key: string, ...params: Array<string>) => {
      // check if value exists
      if (result.messages[key] == null) {
        return key;
      }

      // replace params
      return formatString(result.messages[key], params);
    },

    /**
     * Generate class name with theme
     * specific identifier
     *
     * @param className - css class name
     *
     * @returns {string}
     */
    generateClassName: (className: string) => {
      return `${result.cssIdentifier}-${className}`;
    },
  };
}

/**
 * Wrapper for config.
 *
 * Loads config from server, and provides config context.
 *
 * @param {MessagesWrapperProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.4
 *
 * @returns {Node}
 */
export function ConfigWrapper(props: ConfigWrapperProps): Node {
  const { config, children } = props;

  const messageContextValue = createConfigContextValue(config);

  // return provider
  return (
    <ConfigContext.Provider value={messageContextValue}>
      {children}
    </ConfigContext.Provider>
  );
}
