// @flow strict

import { type Node, useState } from 'react';
import clsx from 'clsx';

import { Modal } from './modal';
import { useConfigContext } from '../config-wrapper';

import './question-answer-modal.less';
import { usePrintQuestion } from '../../hooks/print-question';

type QuestionAnswerModalProps = {
  headline: string,
  className: string,
  onClose: () => void,
  children: Node,
};

/**
 * Modal popover-like component that shows a modal
 * window with a headline of a question and
 * a clickable close icon.
 *
 * @param {string} headline - question headline
 * @param {string} className - css name to specify class names
 * @param {Node} children - modal content
 * @param {Function} onClose - callback for close event
 *
 * @returns {Node}
 */
export const QuestionAnswerModal = ({
  headline,
  className,
  children,
  onClose,
}: QuestionAnswerModalProps): Node => {
  const config = useConfigContext();
  const { generateClassName } = config;

  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState<boolean>(false);

  // Opens the browser's print dialog and applies print specific CSS
  // to be able to print the active item
  usePrintQuestion({
    isPrintEnabled: isPrintDialogOpen,
    setIsPrintEnabled: setIsPrintDialogOpen,
    filePath: '/help/assets/print-style.css',
  });

  return (
    <Modal
      container={generateClassName(`${className}`)}
      styleName={clsx(
        generateClassName('question-answer-modal'),
        generateClassName('popover'),
        generateClassName(`${className}-popover`),
      )}
      onClose={onClose}>
      <div
        className={clsx(
          generateClassName('popover__wrapper'),
          generateClassName(`${className}-popover__wrapper`),
        )}>
        <div>
          {/* add wrapper for header, since safari has problems with nested flex containers */}
          <div
            className={clsx(
              generateClassName('popover__header'),
              generateClassName(`${className}-popover__header`),
            )}>
            <h1
              className={clsx(
                generateClassName('popover__headline'),
                generateClassName(`${className}-popover__headline`),
              )}>
              {headline}
            </h1>
            {config.isPrintingActive && (
              <button
                onClick={() => setIsPrintDialogOpen(true)}
                title={config.loc('print')}
                className={config.generateClassName(
                  'question-list__print-button',
                )}>
                <i
                  className={config.generateClassName(
                    'question-list__print-icon',
                  )}
                />
              </button>
            )}
            <div
              className={clsx(
                generateClassName('popover__close-icon'),
                generateClassName(`${className}-popover__close-icon`),
              )}
              onClick={onClose}
            />
          </div>
        </div>

        {children}
      </div>
    </Modal>
  );
};
