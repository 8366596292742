// @flow strict

import { type Node } from 'react';

import type { InitialData, Placeholder } from '@omq/flow';

import { ConfigWrapper } from './config-wrapper';
import { CookieSessionWrapper } from './cookie-session-wrapper';
import { useInitialData } from '../hooks/initialize';
import { IntegrationStyle } from './integration-style';
import { useCookie } from '../hooks/cookie';

/**
 * Component properties.
 */
export type InitialDataProps<R: InitialData> = {
  cookieIsEnabled?: boolean,
  cookieName: string,
  params?: {},
  placeholder: Placeholder,
  onReady: (data: R) => Node,
};

/**
 * Wrapper component that handles initial data loading.
 *
 * @param category - id of category
 * @param cookieIsEnabled - flag to enable/disable cookies
 * @param cookieName - name of the cookie
 * @param onReady - handle result callback
 *
 * @returns {Node}
 */
export function InitialDataWrapper<T: InitialData>({
  params,
  cookieIsEnabled,
  cookieName,
  onReady,
  placeholder,
}: InitialDataProps<T>): Node {
  const [cookie] = useCookie(cookieName);

  // load initial data
  // if cookie is enabled & cookie is already set
  // re-use session
  const data = useInitialData<T>(
    params,
    placeholder,
    cookie != null ? cookie.session : null,
  );

  if (data == null) {
    return 'Loading...';
  }

  return (
    <ConfigWrapper config={data.config}>
      <CookieSessionWrapper
        name={cookieName}
        sessionId={data.session.id}
        cookieIsEnabled={
          cookieIsEnabled != null
            ? cookieIsEnabled
            : data.config.isDefaultCookieActive
        }>
        <IntegrationStyle style={data.style} />
        {onReady(data)}
      </CookieSessionWrapper>
    </ConfigWrapper>
  );
}
