// @flow strict

import type { FaqCategory } from './faq-category';

export const ConfigAnswerDisplayTypes = {
  INLINE: 'INLINE',
  POPUP: 'POPUP',
};

export type ConfigAnswerDisplayType = $Keys<typeof ConfigAnswerDisplayTypes>;

export const ConfigCategoryDisplayTypes = {
  PERMANENT: 'PERMANENT',
  DISAPPEAR: 'DISAPPEAR',
};

export type ConfigCategoryDisplayType = $Keys<
  typeof ConfigCategoryDisplayTypes,
>;

export const ConfigMobileSearchDisplayTypes = {
  INLINE: 'INLINE',
  POPUP: 'POPUP',
};

export type ConfigMobileSearchDisplayType = $Keys<typeof ConfigMobileSearchDisplayTypes>;

export const ConfigURLPatternTypes = {
  QUERY_PARAM: 'QUERY_PARAM',
  PATH: 'PATH',
};

export type ConfigURLPatternType = $Keys<typeof ConfigURLPatternTypes>;

/**
 * Type for Config.
 *
 * Includes, UI messages & other config provided by server.
 */
export type Config = {
  messages: {
    [key: string]: string,
  },

  isAutoFocusActive: boolean,
  isDefaultCookieActive: boolean,
  isFeedbackActive: boolean,
  isPrintingActive: boolean,
  isPoweredByActive: boolean,
  answerDisplayType: ConfigAnswerDisplayType,
  categoryDisplayType: ConfigCategoryDisplayType,
  mobileSearchDisplayType: ConfigMobileSearchDisplayType,

  urlPatternType: ConfigURLPatternType,
  cssIdentifier: string,
  categories: Array<FaqCategory>,
};

/**
 * Type for answer placeholders
 */
export type Placeholder = {
  [key: string]: mixed,
};
